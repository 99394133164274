import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TrbmContent from "src/components/modules/trbmcontent"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import SimplyTemp from "src/components/modules/simplyTemp"

const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;
const Img2 = () => <img className="as" src="/img/as-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;

export default function prdbest() {

let module;
  module = null;
 if(isChrome()){
    module = <TrbmContent data={prdbestData}><Img />
    <CtaDisclosureSearch  data={prdbestData}></CtaDisclosureSearch>
    </TrbmContent>
  }
  else if(isFirefox()){
    module = <TrbmContent data={prdbestData}><Img />
    <CtaDisclosure data={prdbestData}></CtaDisclosure></TrbmContent>
  }
  else if(isSafari()){
   module = <SimplyTemp data={prdbestData}><Img2 /></SimplyTemp>;
 }
 else if(isIeEdge()){
  module = <SimplyTemp data={prdbestData}></SimplyTemp>;
 }

     let css;
       if(isFirefox()){
         css = `.trbmcontent-module--trbm .trbmcontent-module--bullets {
           padding-left: 35px;
         }
         .cws {
           display: none;
         }
           .trbm-module--trbm button {
             padding: 20px 80px!important;
             
           }
         #ctadisclosure-module--ctadisclaimer {
             padding: 25px 7% 0;
           }
         }`;
       }
       else if(isIeEdge()){
        css = `
        .simply-module--simplyWrap button.simply-module--install:hover {
          transform: none !important;
          cursor: auto !important;
          }
        .simply-module--simplyWrap button.simply-module--install {
          cursor:auto !important; 
        }
        }`;
      }
       else{
         css = `.trbm-module--trbm .trbm-module--bullets {
           padding-left: 0;
         }
         .cws {
           width: 200px;
           display: block;
           margin: 10px auto;
           cursor:pointer;
         }
         .as {
          width: 150px;
          display: block;
          margin: 10px auto;
          cursor:pointer;
        }
         #ctadisclosure-module--ctadisclaimer {
           padding: 0px 7%;
           padding-bottom: 0;

         }`;
       }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
background-image: url(/assets/recipesbmlp.jpg) !important;
background-color: #c5e3e4 !important;;
background-repeat: no-repeat !important;;
-webkit-background-size: cover !important;;
-moz-background-size: cover !important;;
-o-background-size: cover !important;;
background-size: cover !important;;
}

button.trbm_cta {
  font-weight: normal;
  font-size: 30px;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 7%;
  padding-bottom: 0;
}

.simply-module--simplyWrap h1, .simply-module--simplyWrap p {
  color:#333 !important;
}
.simply-module--simplyWrap .simply-module--atf {
  max-height:auto !important;
  background: #fff;
  width: 800px !important;
  border-radius: 10px;
  border: 1px solid #ccc;
  text-align: center;
  box-sizing: border-box;
  padding: 20px !important;
  margin: 40px auto !important;
}

.simply-module--minHeight {
  min-height:100vh;
}

`}
   </style>

   <style type="text/css">
     {css}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      {module}
    </HomepageLayout>
  )
}
