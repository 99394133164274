import React from "react"
import JSONData from "branding/lp-metadata.json"
import SimplyData from "./data/data.json"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import Styles from "./css/simply.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"

class SimplyTemp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: SimplyData
    }
  }
  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'safari' && browsers.includes('safari')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isSafari = isSafari();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(isSafari()){
      const browserData = Object.assign(this.state.data, this.state.data.safari);
      this.setState({data: browserData});
    }
    if(isIeEdge()){
      const browserData = Object.assign(this.state.data, this.state.data.edge);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
    });
  }

  render() {
    return (

      <div className = {Styles.simplyWrap} style={{ display: this.state.display }}>
        <div className = {Styles.minHeight}>
        <div className = {Styles.atf}>
          <img className = {Styles.logo} src = {this.state.data.prodLogo}></img>
        		<h1 className={Styles.bm}>{this.state.data.headline}</h1>
              <h3>{this.state.data.subhead}</h3>
              <button className = {Styles.install} data-cy="cta" onClick = {() => window.triggerInstall()}
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
              {this.props.children}
        		<CtaDisclosureSearch></CtaDisclosureSearch>
        </div>
        </div>
        <div className = {Styles.belowtf}>
          <h2>{this.state.data.belowHeadline}</h2>
          <div className = {Styles.gridWrap}>

            <div className = {Styles.gridItem}>
              <div className = {Styles.gridCard}>
              <img className = {Styles.icon} src={this.state.data.icon1}></img>
              <div className = {Styles.circle}></div>
              <p className = {Styles.gridTitle}>{this.state.data.title1}</p>
              <p className = {Styles.gridInfo}>{this.state.data.info1}</p>
              </div>
            </div>

            <div className = {Styles.gridItem}>
              <img className = {Styles.cenImg} src={this.state.data.centerImg}></img>
            </div>

            <div className = {Styles.gridItem}>
              <div className = {Styles.gridCard}>
              <img className = {Styles.icon} id={Styles.browser} src={this.state.data.icon3}></img>
              <div className = {Styles.circle}></div>
                <p className = {Styles.gridTitle}>{this.state.data.title3}</p>
                <p className = {Styles.gridInfo}>{this.state.data.info3}</p>
            </div>
          </div>
        </div>

          <button className = {Styles.install} data-cy="cta" onClick = {() => window.triggerInstall()}
            dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
          {this.props.children}

        		<div className={Styles.br}>
        		Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Terms</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/contact-us/" target="_blank">Contact</a> | <a href="/uninstall/" target="_blank">Uninstall</a> | <a href="/unsubscribe/"  target="_blank">Unsubscribe</a>  <br/> <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a> | All trademarks are property of their respective owners
          </div>
        </div>
      </div>






    )
  }
}

export default SimplyTemp
